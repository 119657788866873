import { Module } from "vuex"
import axios from "@/config/axios.config";
import { RootState } from "../types"
import { Tournaments } from "@/views/League/components/TournamentsTab/types";

// TODO colocar a tipagem do módulo
const tournamentsModule: Module<any, RootState> = {
  namespaced: true,
  state: {
    tournaments: [],
  },

  mutations: {
    setTournaments(state: any, payload: Tournaments) {
      state.tournaments = payload
    },
  },

  actions: {
    fetch({ commit }, payload) {
      return axios
        .get(`/tournaments`, {
          params: payload
        })
        .then(({ data }) => {
          console.log(data)
          commit("setTournaments", data.tournaments)
        })
        .catch(err => {
          console.log(err)
          // context.commit("setError", err.response.data.error);
        });
    },

    save({ dispatch }, payload) {
      return axios
        .post(`/tournaments`, payload)
        .then(({ data }) => {
          console.log(data)
        })
        .catch(err => {
          console.log(err)
          // context.commit("setError", err.response.data.error);
        });
    },
  },

  getters: {
    getTournaments: function(state: any): Array<Tournaments> {
      return state.tournaments
    }
  }
}

export default tournamentsModule
