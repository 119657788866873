import { Module } from "vuex"
import axios from "@/config/axios.config";
import { RootState, Standings } from "../types"

// TODO colocar a tipagem do módulo
const standingsModule: Module<any, RootState> = {
  namespaced: true,
  state: {
    standings: [],
  },

  mutations: {
    setStandings(state: any, payload: Array<Standings>) {
      state.standings = payload
    },
  },

  actions: {
    fetchByLeague({ commit }, payload) {
      axios
        .get(`/standing/league/new/${payload}`)
        .then(({ data }) => {
          commit("setStandings", data.standings)
        })
        .catch(err => {
          console.log(err)
          commit("setError", "Ocorreu um erro!");
        });
    },

    updateOne({ dispatch, commit }, payload) {
      axios
        .put(`/standing/update`, payload)
        .then(({ data }) => {
          dispatch("fetchByLeague", payload.league)
        })
        .catch(err => {
          console.log(err)
          commit("setError", "Ocorreu um erro!");
        });
    },
  },

  getters: {
    getStandings: function(state: any): Array<Standings> {
      return state.standings
    }
  }
}

export default standingsModule
