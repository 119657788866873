import { Module } from "vuex"
import axios from "axios"
import { HerokuApps, RootState } from "../types"

const HEROKU_API = process.env.VUE_APP_HEROKU_API
const HEROKU_AUTH_TOKEN = process.env.VUE_APP_HEROKU_AUTH_TOKEN

const AXIOS_CONFIG = {
  baseURL: HEROKU_API,
  headers: {
    "Authorization": "Bearer " + HEROKU_AUTH_TOKEN,
    "Accept": "application/vnd.heroku+json; version=3"
  }
}

// VER DOC https://devcenter.heroku.com/articles/platform-api-reference
const herokuModule: Module<any, RootState> = {
  namespaced: true,
  state: {
    apps: []
  },

  mutations: {
    setApps(state: any, payload: Array<HerokuApps>) {
      state.apps = payload
    }
  },

  actions: {
    fetchApps({ commit }) {
      axios
        .get('/apps', AXIOS_CONFIG)
        .then(({ data }) => commit("setApps", data))
    },

    restartAllDynos({ commit }, appId) {
      axios
        .delete('/apps/' + appId + "/dynos", AXIOS_CONFIG)
        .then(console.log)
      // commit("setCollections", collections)
    },

    listDynos({ commit }, appId) {
      axios
        .get('/apps/' + appId + "/dynos", AXIOS_CONFIG)
        .then(console.log)
      // commit("setCollections", collections)
    },
  },

  getters: {
    getApps: function(state: any): Array<HerokuApps> {
      return state.apps
    }
  }
}

export default herokuModule
