import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: "*",
    redirect: "/home"
  },
  {
    path: "/",
    redirect: "/home"
  },
  {
    path: "/home",
    name: "Página Inicial",
    component: () => import("../views/Home.vue")
  },
  {
    path: "/redirect",
    name: "Redirect",
    component: () => import("../views/Redirect.vue")
  },
  {
    path: "/heroku-apps",
    name: "Heroku Apps",
    component: () => import("../views/HerokuApps/HerokuApps.vue")
  },
  {
    path: "/leagues",
    name: "Ligas",
    component: () => import("../views/Leagues/LeaguesPage.vue")
  },
  {
    path: "/league/:id",
    name: "Liga",
    component: () => import("../views/League/LeaguePage.vue")
  },
  {
    path: "/fixtures",
    name: "Partidas",
    component: () => import("../views/League/components/FixturesTab/index.vue")
  },
  {
    path: "/users",
    name: "Usuários",
    component: () => import("../views/Users/Users.vue")
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if(to.matched.some(record => record.meta.requiresAuth)) {
//     if (!localStorage.getItem('token')) {
//       next('/login')
//       return
//     }
//   }
//   next()
// })

export default router
