import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
// @ts-ignore
import VueNumber from 'vue-number-animation'

import SocketIO from 'socket.io-client';
import VueSocketIO from 'vue-socket.io-extended';

// Reference https://www.npmjs.com/package/vue-socket.io-extended
const socket = SocketIO(process.env.VUE_APP_SOCKET_API, {
  path: '/socket.io',
  transports : ['websocket']
})

Vue.use(VueNumber)
Vue.use(VueSocketIO, socket, { store });

Vue.config.productionTip = false;
Vue.prototype.$store = store;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
