import { Module } from "vuex"
import axios from "@/config/axios.config.ts"
import { User } from "firebase"

const authModule: Module<any, any> = {
  namespaced: true,
  state: {
    user: null,
    loading: true
  },

  mutations: {
    setUser(state: any, payload: User) {
      state.user = payload
    },
    setLoading(state: any, payload: Boolean) {
      state.loading = payload
    }
  },

  actions: {
    fetchUser({ commit }) {
      const user = localStorage.getItem("@FootScores:Token")
      commit("setUser", user)
      commit("setLoading", false)
    },

    async login({ commit }, payload: any) {
      await axios
        .post(`/auth/login`, payload)
        .then(({ data }) => {
          localStorage.setItem("@FootScores:Token", data["token"]);
          commit("setUser", data["token"])
        })
        .catch(err => {
          console.log(err)
          // context.commit("setError", err.response.data.error);
        });
    },

    logout: ({ commit }) => {
      localStorage.clear();
      commit("setUser", null)
    },
  },

  getters: {
    getUser: (state: any) => state.user,
    getLoading: (state: any) => state.loading
  }
}

export default authModule
