import { Module } from "vuex"
import axios from "@/config/axios.config";
import { RootState, Country } from "../types"

// TODO colocar a tipagem do módulo
const countriesModule: Module<any, RootState> = {
  namespaced: true,
  state: {
    countries: [],
  },

  mutations: {
    setCountries(state: any, payload: Country) {
      state.countries = payload
    },
  },

  actions: {
    fetch({ commit }, payload) {
      axios
        .get(`/country/get-all`, {
          params: payload
        })
        .then(({ data }) => {
          console.log(data.countries)
          commit("setCountries", data.countries)
        })
        .catch(err => {
          console.log(err)
          // context.commit("setError", err.response.data.error);
        });
    },
  },

  getters: {
    getCountries: function(state: any): Array<Country> {
      return state.countries
    },
  }
}

export default countriesModule
