import { Module } from "vuex"
import axios from "@/config/axios.config";
import { RootState, League } from "../types"

// TODO colocar a tipagem do módulo
const leaguesModule: Module<any, RootState> = {
  namespaced: true,
  state: {
    league: {},
    leagues: [],
    rounds: [],
    // teams: [],
    groups: [],
    punishments: [],
  },

  mutations: {
    setLeague(state: any, payload: League) {
      state.league = payload
    },
    setLeagues(state: any, payload: League) {
      state.leagues = payload
    },
    setRounds(state: any, payload: Array<string>) {
      state.rounds = payload
    },
    setGroups(state: any, payload: Array<any>) {
      state.groups = payload
    },
    setPunishments(state: any, payload: Array<any>) {
      state.punishments = payload
    },
  },

  actions: {
    fetch({ commit }, payload) {
      axios
        .get(`/league`, {
          params: payload
        })
        .then(({ data }) => {
          console.log(data.leagues)
          commit("setLeagues", data.leagues)
        })
        .catch(err => {
          console.log(err)
          // context.commit("setError", err.response.data.error);
        });
    },

    fetchOne({ commit }, payload) {
      axios
        .get(`/league/id/${payload}`, {
          params: payload
        })
        .then(({ data }) => {
          console.log(data)
          commit("setLeague", data.league)
        })
        .catch(err => {
          console.log(err)
          // context.commit("setError", err.response.data.error);
        });
    },

    updateOne({ dispatch }, payload) {
      axios
        .put(`/league/${payload._id}`, payload)
        .then(({ data }) => {
          console.log(data)
          // dispatch("fetch") TODO fazer com o mesmo filtro da tela
        })
        .catch(err => {
          console.log(err)
          // context.commit("setError", err.response.data.error);
        });
    },

    fetchRounds({ commit }, payload) {
      axios
        .get(`/league/${payload}/rounds`)
        .then(({ data }) => {
          console.log(data)
          commit("setRounds", data.rounds)
        })
        .catch(err => {
          console.log(err)
          // context.commit("setError", err.response.data.error);
        });
    },

    fetchLeagueTeams({ commit }, payload) {
      axios
        .get(`/league-teams/${payload}`)
        .then(({ data }) => {
          console.log(data)
          commit("setGroups", data.groups)
          commit("setPunishments", data.punishments)
        })
        .catch(err => {
          console.log(err)
          // context.commit("setError", err.response.data.error);
        });
    },
  },

  getters: {
    getLeague: function(state: any): Array<League> {
      return state.league
    },
    getLeagues: function(state: any): League {
      return state.leagues
    },
    getRounds: function(state: any): Array<string> {
      return state.rounds
    },
    getGroups: function(state: any): Array<any> {
      return state.groups
    },
    getPunishments: function(state: any): Array<any> {
      return state.punishments
    },
  }
}

export default leaguesModule
