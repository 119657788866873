import { Module } from "vuex"
import axios from "@/config/axios.config";
import { RootState, Fixture } from "../types"

// TODO colocar a tipagem do módulo
const fixturesModule: Module<any, RootState> = {
  namespaced: true,
  state: {
    fixtures: [],
    total: 0
  },

  mutations: {
    setFixtures(state: any, payload: Array<Fixture>) {
      state.fixtures = payload
    },
    setTotal(state: any, payload: number) {
      state.total = payload
    }
  },

  actions: {
    fetch({ commit }, payload) {
      return axios
        .get(`/fixtures/paginated`, { params: payload })
        .then(({ data }) => {
          commit("setFixtures", data.fixtures)
          commit("setTotal", data.total)
        })
        .catch(err => {
          console.log(err)
          // context.commit("setError", err.response.data.error);
        });
    },

    requestToUpdate({ dispatch }, payload) {
      return axios.put(`/fixture/save-sys-daily`, payload)
        .then(() => {
          this.dispatch("app/showIsSuccess")
        })
        .catch(() => this.dispatch("app/showIsError"));
    },
  },

  getters: {
    getFixtures: function(state: any): Array<Fixture> {
      return state.fixtures
    },
    getTotal: function(state: any): number {
      return state.total
    }
  }
}

export default fixturesModule
