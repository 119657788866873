import { Module } from "vuex"
import axios from "@/config/axios.config";
import { RootState, User } from "../types"

// TODO colocar a tipagem do módulo
const usersModule: Module<any, RootState> = {
  namespaced: true,
  state: {
    users: [],
  },

  mutations: {
    setUsers(state: any, payload: User) {
      state.users = payload
    },
  },

  actions: {
    fetch({ commit }, payload) {
      axios
        .get(`/users`, {
          params: payload
        })
        .then(({ data }) => {
          console.log(data)
          commit("setUsers", data.users)
        })
        .catch(err => {
          console.log(err)
          // context.commit("setError", err.response.data.error);
        });
    },

    register({ dispatch }, payload) {
      return axios
        .post(`users`, payload)
        .then(({ data }) => {
          console.log(data)
          dispatch("fetch")
        })
        .catch(err => {
          console.log(err)
          // context.commit("setError", err.response.data.error);
        });
    },

    updateOne({ dispatch }, payload) {
      axios
        .put(`/users`, payload)
        .then(({ data }) => {
          console.log(data)
          dispatch("fetch")
        })
        .catch(err => {
          console.log(err)
          // context.commit("setError", err.response.data.error);
        });
    },
  },

  getters: {
    getUsers: function(state: any): User {
      return state.users
    },
  }
}

export default usersModule
