import router from "@/router";
import axios from "axios";

const baseURL = process.env.VUE_APP_FOOTSCORES_API;
const instance = axios.create({ baseURL });

instance.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("@FootScores:Token");
    if (token) {
      // @ts-ignore
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.clear();
      if (router.currentRoute.name !== 'login') {
        router.push("/");
      }
    }
    return Promise.reject(error);
  }
);

export default instance;