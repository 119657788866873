import Vue from "vue"
import Vuex from "vuex"
import { RootState } from "@/store/types";

import app from "@/store/modules/app"
import auth from "@/store/modules/auth"
import heroku from "@/store/modules/heroku";
import leagues from "@/store/modules/leagues";
import countries from "@/store/modules/countries";
import seasons from "@/store/modules/seasons";
import users from "@/store/modules/users";
import fixtures from "@/store/modules/fixtures";
import teams from "@/store/modules/teams";
import standings from "@/store/modules/standings";
import tournaments from "@/store/modules/tournaments";

Vue.use(Vuex)

const store = new Vuex.Store<RootState>({
  modules: {
    app,
    auth,
    heroku,
    leagues,
    countries,
    seasons,
    users,
    fixtures,
    teams,
    standings,
    tournaments
  }
})

store.dispatch("auth/fetchUser")

export default store
