import { Module } from "vuex"
import axios from "@/config/axios.config";
import { RootState, Team } from "../types"

// TODO colocar a tipagem do módulo
const teamsModule: Module<any, RootState> = {
  namespaced: true,
  state: {
    teams: [],
  },

  mutations: {
    setTeams(state: any, payload: Array<Team>) {
      state.teams = payload
    },
  },

  actions: {
    fetchByLeague({ commit }, payload) {
      axios
        .get(`/team/league/${payload}`)
        .then(({ data }) => {
          console.log(data)
          commit("setTeams", data.teams)
        })
        .catch(err => {
          console.log(err)
          // context.commit("setError", err.response.data.error);
        });
    },

    // updateOne({ dispatch }, payload) {
    //   axios
    //     .put(`/league/${payload._id}`, payload)
    //     .then(({ data }) => {
    //       console.log(data)
    //       // dispatch("fetch") TODO fazer com o mesmo filtro da tela
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       // context.commit("setError", err.response.data.error);
    //     });
    // },
  },

  getters: {
    getTeams: function(state: any): Array<Team> {
      return state.teams
    }
  }
}

export default teamsModule
